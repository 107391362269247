@import '../../assets/scss/variable';

.mg-auto {
  margin: auto;
}
.container-reviews {
  max-width: 600px;
  margin: auto;
  padding-top: 10px;
}
.review-headers {
  z-index: 10;
  margin-top: 15px;
  border-color: 'transparent';
  height: '50px';
}
